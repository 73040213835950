import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_EDI_MONITORING_COL_DEF, transferRowData } from "presentation/constant/EdiMonitoring/EdiMonitoringColumnDefinition";
import { useEdiMonitoringVM } from "presentation/hook/EdiMonitoring/useEdiMonitoringVM";
import { useEdiMonitoringTracked } from "presentation/store/EdiMonitoring/EdiMonitoringProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";


const EdiMonitoringTablePanel: React.FC = () => {
    const [ediMoniState] = useEdiMonitoringTracked();
    const ediMoniVM = useEdiMonitoringVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();


    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        // const columnDefs = INITIAL_EDI_MONITORING_COL_DEF?.slice();

        if (!ediMoniState.selectedEdiMonitoringRows ||
            ediMoniState.selectedEdiMonitoringRows.length <= 0) {
            //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        ediMoniVM.updateSelectedEdiMonitorings(selectedRows);
    }, [ediMoniVM])


    const memoEdiMoniTable = useMemo(() => {
        return (
            <>
                <NbisTable
                    id='edi-monitoring-list-table'
                    isNewColumnSetting={true}
                    columns={INITIAL_EDI_MONITORING_COL_DEF?.slice()}
                    data={transferRowData(ediMoniState.ediMonitorings) || []}
                    showPaginator={false}
                    editable={false}
                    showHeaderIcons={true}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    selectionMode={false}
                    rowSelection={"multiple"}
                    handleSelectionChanged={handleSelectionChange}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 125px)"
                    ref={gridRef}
                />
            </>
        );
    }, [ediMoniState.ediMonitorings, handleSelectionChange])

    return <><TableWrapper>{memoEdiMoniTable}</TableWrapper></>;
}

export default memo(EdiMonitoringTablePanel);
