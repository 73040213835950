import { CustomCellRendererProps } from "ag-grid-react";
import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";
import { EdiMonitoringConstant } from "./EdiMonitoringConstant";

const EDI_MONI_CONST = EdiMonitoringConstant;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_EDI_MONITORING_COL_DEF: any[] = [
    {
        headerName: EDI_MONI_CONST.STATUS,
        field: 'status',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: EDI_MONI_CONST.INV_CR_NO,
        field: 'docNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 170,
    },
    {
        headerName: EDI_MONI_CONST.ISSUED_BY,
        field: 'updatedBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: EDI_MONI_CONST.CREATED_BY,
        field: 'docCreatedBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: EDI_MONI_CONST.BILL_TO_COMPANY,
        field: 'docBillToCmp',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: EDI_MONI_CONST.CHARGE_TYPE,
        field: 'docChargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: EDI_MONI_CONST.ATTA_IND,
        field: 'attachmentIncludedInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: EDI_MONI_CONST.CONS_CODE,
        field: 'docCo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: EDI_MONI_CONST.VSL_CODE,
        field: 'docVsl',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: EDI_MONI_CONST.VOY_CODE,
        field: 'docVoy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: EDI_MONI_CONST.VSL_NAME,
        field: 'docVslName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: EDI_MONI_CONST.SL_VSL_CODE,
        field: 'slVesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: EDI_MONI_CONST.SL_IB_VOY_CODE,
        field: 'slIbVoyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: EDI_MONI_CONST.SL_OB_VOY_CODE,
        field: 'slObVoyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: EDI_MONI_CONST.PO_NO,
        field: 'poNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: EDI_MONI_CONST.SENT_DATE,
        field: 'sentDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
        dataType: "dateTime"

    },
    {
        headerName: EDI_MONI_CONST.RESENT_COUNT,
        field: 'resentCount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: EDI_MONI_CONST.ACK_REC_DATE,
        field: 'ackReceiptDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 210,
        dataType: "dateTime"
    },
    {
        headerName: EDI_MONI_CONST.LAST_UPDATED_DATE,
        field: 'updatedDateTime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 230,
        dataType: "dateTime"
    },
    {
        headerName: EDI_MONI_CONST.ISSUE_TYPE,
        field: 'issueType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },


]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    cellRenderers['status'] = cellRenderWithColorAndCircle;

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    externalFnctions['status'] = calculateStateColor;

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}